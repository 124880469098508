/* 组件容器 */
.loading_cont {
  position: relative;
  overflow: hidden; /* 限制内部的溢出 */
}

/* SVG 加载器 */
#loader {
  display: inline-block;
  position: absolute;
  width: 800px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* SVG 过滤器 */
rect {
  transform-origin: 50% 50%;
}

/* 使用滤镜的 blobs */
.blobs {
  filter: url("#goo");
}

.clickable-layer:hover {
  cursor: none !important;
}